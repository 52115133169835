import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";

import moment from "moment";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import Dropdown from "@/components/dropdown";
import Pagination from "@/components/pagination";
import axios from "../../../../axios/osint.js";
import DatePicker from "vue2-datepicker";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";

export default {
    name: "neo-other",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown": Dropdown,
        "analysis-card": analysisCard,
        "neo-datepicker": DatePicker,
        VueMultiselect,
    },
    props: {},
    data() {
        return {
            loaderController: {
                news: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            allNews: [],
            filters: {},
            headers: [
                {
                    text: "",
                    value: "details",
                },
            ],
            itemKey: "title",
            searchCaseFilter: "",
            selectable: false,
            hasAction: false,
            pagination: {
                news: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                },
            },
            searchKeyword: "",
            categories: "",
            codes: "",
            queries: "",
            start_date: "",
            end_date: "",
            language: {
                label: "English",
                value: "en",
            },
            allLanguages: ["en"],
        };
    },
    computed: {
        news() {
            let data = [];
            const start = (this.pagination.news.page - 1) * this.pagination.news.perPage;
            const end = this.pagination.news.page * this.pagination.news.perPage - 1;
            this.allNews.forEach((news, index) => {
                if (start <= index && index <= end) {
                    data.push(news);
                }
            });
            return data;
        },
    },
    watch: {},
    async mounted() {
        this.case_id = this.$route.params.id;
    },
    methods: {
        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        getTime(item) {
            let date = new Date(item.created_at);
            return date ? moment(item).fromNow() : "";
        },
    },
};
